@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
.custom-navbar {
    text-align: center;
  }
  
  .custom-nav-links .nav-link {
    font-family: 'Bebas Neue', sans-serif; 
    font-size: 20px;
    color: white;
  }
  
  .custom-nav-links .nav-link:hover {
    color: #ccc;
  }
  
body {
    font-family: 'Bebas Neue', sans-serif;
    background-color:#ffffff;
    margin: 0;
    padding: 0;
    color: #333;
    line-height: 1.6;
    font-size: 20px;
}

h2 {
    color: #444;
    font-size: 2em;
    font-family: 'Bebas Neue', sans-serif;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

#about {
    background-color: #fff;
    margin: 40px auto;
    padding: 20px;
    max-width: 800px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: justify;
    font-family: 'Roboto Condensed', sans-serif;
}

#about p {
    font-size: 1em;
    margin: 10px 0 20px;
    text-align: justify;
    text-align-last: left;
}

ul {
    padding-left: 20px;
    list-style-position: outside;
}

li {
    margin-bottom: 10px;
    line-height: 1.6;
}

/* Table Styles */
.projection-table {
    width: 100%; /* Full width within the section */
    max-width: 800px; /* Limit width to match the text body's width */
    margin: 0 auto; /* Center the table */
    border-collapse: collapse;
    margin-bottom: 20px;
}

.projection-table th, .projection-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
}

.projection-table th {
    color: white;
}

.team-logo {
    max-width: 100px;
    margin-bottom: 10px;
    align-content: center;
}

.page-header {
    text-align: center;
    margin: 40px;
    font-size: 2.5rem;
    color: #013a68;
}


@media (max-width: 768px) {
    body {
        font-size: 18px;
    }

    h2 {
        font-size: 1.8em;
    }

    .projection-table th, .projection-table td {
        padding: 8px;
        font-size: 0.8em; 
    }

    .projection-table {
        width: 100%;
    }

    .team-logo {
        max-width: 80px;
    }

    h2 {
        font-size: 1.5em; 
    }

    #about {
        padding: 15px;
        margin: 20px auto;
    }

    .page-header {
        font-size: 2rem;
        margin: 20px;
    }
}

@media (max-width: 480px) {
    .projection-table th, .projection-table td {
        padding: 6px;
        font-size: 0.7em;
    }

    h2 {
        font-size: 1.5em;
    }

    .team-logo {
        max-width: 80px;
    }

    #about {
        padding: 10px;
    }

    .page-header {
        font-size: 1.8rem;
    }
}

.table-container {
    overflow-x: auto;
    margin: 2% auto;
    padding: 0 10px;
    background-color: #ffffff;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.table {
    width: 100%;
    border-collapse: collapse;
    max-width: 800px;
    margin: 0 auto;
    position: relative; /* Ensure that table can handle sticky elements */
}

.table td:first-child, .table th:first-child {
    position: sticky;
    left: 0;
    z-index: 10;
    background-color: #fff; /* Match the default table row background */
    /* border-right: 1px solid #dee2e6; */
}




.table th:first-child {
    background-color: #013a68; /* Match header row background */
}

p {
    text-align: center;
}

/* Styling for table headers and cells */
thead {
    text-align: center;
    background-color: #013a68;
    color: white;
}

td, th {
    text-align: center;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 20px;
    border: 1px solid #dee2e6; /* Add borders to match the rest of the table */
}

.stat-td {
    text-align: center;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-right: 10px;
}

.headerStyleEast th {
    background-color: #960000 !important;
}

.headerStyleWest th {
    background-color: #013a68;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .table {
        font-size: 14px;
    }

    .logo {
        width: 30px;
        height: 30px;
        margin-right: 8px;
    }
    
    .p {
        font-size: 0.5rem;
    }
}

.divisions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 12%;
  margin-top: 1%;
  background-color: #ffffff;
}
.divisionOdds-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 13%;
  margin-top: 1%;
  background-color: #ffffff;
}

.division-column {
  flex: 1 1;
  margin: 10px;
  min-width: 220px;
}

.imageColumn {
  width: 40px;
  height: 40px;
  object-fit: contain; 
  margin-right: 10px; 
}

.imageColumnOdds {
  width: 40px;
  height: 40px;
  object-fit: contain; 
  margin-right: 10px; 
}

.abrvColumn {
  font-size: 28px;
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;
}

.pointsColumn {
  font-size: 28px;
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;
}

.abrvColumnOdds {
  font-size: 20px;
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;
}

.oddsColumn {
  font-size: 20px;
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;
}


.headerStyleEast, .th {
  background-color: #960000;
  /* color: #ffffff;  */
}

.headerStyleWest {
  background-color: #013a68;
  /* color: #ffffff;  */
}

h1 {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 2em;
  font-weight: normal;
  color: #333;
  text-transform: uppercase;
  text-align: center;
}

@media (max-width: 768px) {
  .imageColumn {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .divisions-container {
    flex-direction: column;
  }

  .division-column {
    margin: 5px 0;
  }
}

.card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 5%;
    margin-bottom: 5%;
}

.logo-section {
    text-align: center;
    /* margin-bottom: 10px; */
}

.big-logo {
    width: 120px;
    height: 150px;
    object-fit: cover;
}

.table {
    margin-bottom: 0;
}

.stats-section-card {
    width: 80%;
}

.stat-td {
    text-align: center;
}

.stat-td, .category-td {
    font-size: 24px;
}

.category-td {
    text-align: left;
}

.mini-logo-container {
    display: flex;
    justify-content: right;
    margin-top: 5px;
}

.mini-logo {
    width: 50px;
    height: 50px;
}

/* 
Apply the iPhone layout for devices up to 1024px */
@media (min-width: 1024px) {
    .card-container {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: 70%;
        max-width: 950px;
        padding: 40px;
    }

    .logo-section {
        text-align: left;
        margin-bottom: 0;
        margin-right: 80px;
    }

    .big-logo {
        width: 300px;
        height: auto;
    }

    .stats-section-card {
        width: 60%;
    }
    
}

/* For iPad and smaller screens */
@media (max-width: 768px) {
    .card-container, .logo-section, .big-logo, .stats-section-card {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 350px;
        padding: 10px;
    }

    .logo-section {
        text-align: center;
        /* margin-bottom: 20px; */
        margin-right: 0;
    }

    .big-logo {
        width: 175px;
        height: auto;
    }

    .stats-section-card {
        width: 100%;
    }
    
    .category-td, .stat-td{
        font-size: 18px;
    }

    .mini-logo-container {
        display: flex;
        justify-content: center;
        margin-top: 5px;
    }
    
    .mini-logo {
        width: 40px;
        height: 40px;
    }
}

.power-table-container {
    width: 60%;
    margin: 2% auto;
    padding: 20px;
    background-color: #ffffff;
    align-items: center;
  }
  
  .ranked-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-gap: 10px;
    gap: 10px;
  }
  
  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f4f4;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: center;
  }
  
  .grid-item .rank {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .logo-container-grid {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-grid {
    width: 80px;
    height: 80px;
    /* margin-right: 10px; */
  }
  
  .tier-section {
    margin-bottom: 30px;
  }
  
  .tier-section h2 {
    text-align: center;
    margin-bottom: 15px;
  }
  
  .power-table-container table {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    border-collapse: collapse;
  }
  
  .power-table-container th {
    background-color: #013a68;
    padding: 10px;
  }
  
  .power-table-container td {
    padding: 15px;
  }
  
  .logo-container {
    display: flex;
  }
  
  .logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .tier-section {
    margin-bottom: 30px;
  }
  
  .tier-section h2 {
    text-align: center;
    margin-bottom: 15px;
  }

  .rank-change {
    margin-left: 5px;
    font-size: 0.9em;
    font-weight: bold;
  }
  
  .rank-change.up {
    color: green;
  }
  
  .rank-change.down {
    color: red;
  }
  
  .rank-change.unchanged {
    color: gray;
  }
  
  
.final-results-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 10px;
  }
  
  .final-results-page h1 {
    font-size: 2.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .final-results-page table {
    font-size: 0.9vw;
    width: 100%;
    max-height: 90vh;
    overflow: hidden;
    table-layout: fixed;
    white-space: nowrap;
  }
  
  .final-results-page th,
  .final-results-page td {
    padding: 5px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .final-results-page .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .final-results-page .logo {
    width: 30px;
    height: 30px;
  }
  
  .final-results-page .stat-td {
    padding: 3px;
    font-size: 1.2vw;
  }
  
