.card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 5%;
    margin-bottom: 5%;
}

.logo-section {
    text-align: center;
    /* margin-bottom: 10px; */
}

.big-logo {
    width: 120px;
    height: 150px;
    object-fit: cover;
}

.table {
    margin-bottom: 0;
}

.stats-section-card {
    width: 80%;
}

.stat-td {
    text-align: center;
}

.stat-td, .category-td {
    font-size: 24px;
}

.category-td {
    text-align: left;
}

.mini-logo-container {
    display: flex;
    justify-content: right;
    margin-top: 5px;
}

.mini-logo {
    width: 50px;
    height: 50px;
}

/* 
Apply the iPhone layout for devices up to 1024px */
@media (min-width: 1024px) {
    .card-container {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: 70%;
        max-width: 950px;
        padding: 40px;
    }

    .logo-section {
        text-align: left;
        margin-bottom: 0;
        margin-right: 80px;
    }

    .big-logo {
        width: 300px;
        height: auto;
    }

    .stats-section-card {
        width: 60%;
    }
    
}

/* For iPad and smaller screens */
@media (max-width: 768px) {
    .card-container, .logo-section, .big-logo, .stats-section-card {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 350px;
        padding: 10px;
    }

    .logo-section {
        text-align: center;
        /* margin-bottom: 20px; */
        margin-right: 0;
    }

    .big-logo {
        width: 175px;
        height: auto;
    }

    .stats-section-card {
        width: 100%;
    }
    
    .category-td, .stat-td{
        font-size: 18px;
    }

    .mini-logo-container {
        display: flex;
        justify-content: center;
        margin-top: 5px;
    }
    
    .mini-logo {
        width: 40px;
        height: 40px;
    }
}
