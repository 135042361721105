.table-container {
    overflow-x: auto;
    margin: 2% auto;
    padding: 0 10px;
    background-color: #ffffff;
    max-width: fit-content;
}

.table {
    width: 100%;
    border-collapse: collapse;
    max-width: 800px;
    margin: 0 auto;
    position: relative; /* Ensure that table can handle sticky elements */
}

.table td:first-child, .table th:first-child {
    position: sticky;
    left: 0;
    z-index: 10;
    background-color: #fff; /* Match the default table row background */
    /* border-right: 1px solid #dee2e6; */
}




.table th:first-child {
    background-color: #013a68; /* Match header row background */
}

p {
    text-align: center;
}

/* Styling for table headers and cells */
thead {
    text-align: center;
    background-color: #013a68;
    color: white;
}

td, th {
    text-align: center;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 20px;
    border: 1px solid #dee2e6; /* Add borders to match the rest of the table */
}

.stat-td {
    text-align: center;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-right: 10px;
}

.headerStyleEast th {
    background-color: #960000 !important;
}

.headerStyleWest th {
    background-color: #013a68;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .table {
        font-size: 14px;
    }

    .logo {
        width: 30px;
        height: 30px;
        margin-right: 8px;
    }
    
    .p {
        font-size: 0.5rem;
    }
}
