@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@100..900&display=swap');

body {
    font-family: 'Bebas Neue', sans-serif;
    background-color:#ffffff;
    margin: 0;
    padding: 0;
    color: #333;
    line-height: 1.6;
    font-size: 20px;
}

h2 {
    color: #444;
    font-size: 2em;
    font-family: 'Bebas Neue', sans-serif;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

#about {
    background-color: #fff;
    margin: 40px auto;
    padding: 20px;
    max-width: 800px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: justify;
    font-family: 'Roboto Condensed', sans-serif;
}

#about p {
    font-size: 1em;
    margin: 10px 0 20px;
    text-align: justify;
    text-align-last: left;
}

ul {
    padding-left: 20px;
    list-style-position: outside;
}

li {
    margin-bottom: 10px;
    line-height: 1.6;
}

/* Table Styles */
.projection-table {
    width: 100%; /* Full width within the section */
    max-width: 800px; /* Limit width to match the text body's width */
    margin: 0 auto; /* Center the table */
    border-collapse: collapse;
    margin-bottom: 20px;
}

.projection-table th, .projection-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
}

.projection-table th {
    color: white;
}

.team-logo {
    max-width: 100px;
    margin-bottom: 10px;
    align-content: center;
}

.page-header {
    text-align: center;
    margin: 40px;
    font-size: 2.5rem;
    color: #013a68;
}


@media (max-width: 768px) {
    body {
        font-size: 18px;
    }

    h2 {
        font-size: 1.8em;
    }

    .projection-table th, .projection-table td {
        padding: 8px;
        font-size: 0.8em; 
    }

    .projection-table {
        width: 100%;
    }

    .team-logo {
        max-width: 80px;
    }

    h2 {
        font-size: 1.5em; 
    }

    #about {
        padding: 15px;
        margin: 20px auto;
    }

    .page-header {
        font-size: 2rem;
        margin: 20px;
    }
}

@media (max-width: 480px) {
    .projection-table th, .projection-table td {
        padding: 6px;
        font-size: 0.7em;
    }

    h2 {
        font-size: 1.5em;
    }

    .team-logo {
        max-width: 80px;
    }

    #about {
        padding: 10px;
    }

    .page-header {
        font-size: 1.8rem;
    }
}
