@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.divisions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 12%;
  margin-top: 1%;
  background-color: #ffffff;
}
.divisionOdds-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 13%;
  margin-top: 1%;
  background-color: #ffffff;
}

.division-column {
  flex: 1;
  margin: 10px;
  min-width: 220px;
}

.imageColumn {
  width: 40px;
  height: 40px;
  object-fit: contain; 
  margin-right: 10px; 
}

.imageColumnOdds {
  width: 40px;
  height: 40px;
  object-fit: contain; 
  margin-right: 10px; 
}

.abrvColumn {
  font-size: 28px;
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;
}

.pointsColumn {
  font-size: 28px;
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;
}

.abrvColumnOdds {
  font-size: 20px;
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;
}

.oddsColumn {
  font-size: 20px;
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;
}


.headerStyleEast, .th {
  background-color: #960000;
  /* color: #ffffff;  */
}

.headerStyleWest {
  background-color: #013a68;
  /* color: #ffffff;  */
}

h1 {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 2em;
  font-weight: normal;
  color: #333;
  text-transform: uppercase;
  text-align: center;
}

@media (max-width: 768px) {
  .imageColumn {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .divisions-container {
    flex-direction: column;
  }

  .division-column {
    margin: 5px 0;
  }
}
