.power-table-container {
    width: 60%;
    margin: 2% auto;
    padding: 20px;
    background-color: #ffffff;
    align-items: center;
  }
  
  .ranked-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 10px;
  }
  
  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f4f4;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: center;
  }
  
  .grid-item .rank {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .logo-container-grid {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-grid {
    width: 80px;
    height: 80px;
    /* margin-right: 10px; */
  }
  
  .tier-section {
    margin-bottom: 30px;
  }
  
  .tier-section h2 {
    text-align: center;
    margin-bottom: 15px;
  }
  
  .power-table-container table {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    border-collapse: collapse;
  }
  
  .power-table-container th {
    background-color: #013a68;
    padding: 10px;
  }
  
  .power-table-container td {
    padding: 15px;
  }
  
  .logo-container {
    display: flex;
  }
  
  .logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .tier-section {
    margin-bottom: 30px;
  }
  
  .tier-section h2 {
    text-align: center;
    margin-bottom: 15px;
  }

  .rank-change {
    margin-left: 5px;
    font-size: 0.9em;
    font-weight: bold;
  }
  
  .rank-change.up {
    color: green;
  }
  
  .rank-change.down {
    color: red;
  }
  
  .rank-change.unchanged {
    color: gray;
  }
  
  